<template>
  <a-modal title="交付物审批流设置" v-model="visible" width="800px" wrapClassName="relationWorkModal" @ok="ok" :confirm-loading="confirmLoading">
    <a-spin :spinning="loading">
      <div class="relation-work">
      <div class="work-list" style="padding-top: 20px">
        <a-table :data-source="form.items" :pagination="false" style="margin-bottom: 19px;">
          <a-table-column title="步骤序号" width="100px">
            <template #default="_, row, index">
              <span>{{ index + 1 }}</span>
            </template>
          </a-table-column>
          <a-table-column title="步骤名称" width="150px">
            <template #default="_, row">
              <a-input v-model="row.step_name" />
            </template>
          </a-table-column>
          <a-table-column title="步骤角色" width="120px">
            <template #default="_, row">
              <div style="display: flex;align-items: center">
                <a-select v-model="row.step_role" style="width: 100%">
                  <a-select-option v-for="item in allRole" :key="item.oid" :value="item.oid">{{item.role_name}}</a-select-option>
                </a-select>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="是否最终" width="120px">
            <template #default="_, row, index">
              <a-select style="width: 100%" v-model="row.is_end">
                <a-select-option value="0" >否</a-select-option>
                <a-select-option value="1" :disabled="index + 1 !== form.items.length">是</a-select-option>
              </a-select>
            </template>
          </a-table-column>
          <a-table-column title="操作" width="70px">
            <template v-slot:default="_, row, index">
              <span class="blue" @click="deleteItem(index)">
                删除
              </span>
            </template>
          </a-table-column>
        </a-table>

        <div style="display: flex" v-if="!hasEnd">
          <div class="adddeliver blue" style="cursor: pointer;" @click="addDeliver">
            <a-icon type="plus" />
            新增审批流
          </div>
        </div>
      </div>
    </div>
    </a-spin>
  </a-modal>
</template>

<script>
import {fetch} from "@/utils/request.js";

export default {
  name: "DeliverModal",
  emits: ["reload"],
  data() {
    return {
      visible: false,
      form: {
        prjoid: "",
        delivery_type: "",
        items: []
      },
      loading: false,
      confirmLoading: false,
      allRole: [],
    }
  },
  methods: {
    async deleteItem(index) {
      let item = this.form.items[index]
      if (item && item.oid) {
        await fetch("post", "/prj/wfdefine/delete", {
          oid: item.oid
        }).then(res => {
          if (res.status !== 0) {
            this.$message.error(res.message)
            return Promise.reject()
          }
        })
      }
      this.form.items.splice(index, 1)
    },
    getAllRole(prj_oid) {
      return fetch("post", "prj/all/role/retrieve/byprj", {
        prjoid: prj_oid,
      }).then(res => {
        if (res.status === 0) {
          this.allRole = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addDeliver() {
      this.form.items.push({
        step_seq: '', step_name: '', step_role: '', is_end: '0'
      })
    },
    async open(row, prj_oid) {
      this.visible = true
      this.loading = true
      await this.getAllRole(prj_oid)
      await fetch("post", "/prj/wfdefine/retrieve", {
        delivery_type: row.cat_code,
        prjoid: prj_oid,
        page_size: 999
      }).then(res => {
        if (res.status === 0) {
          this.form = {
            delivery_type: row.cat_code,
            prjoid: prj_oid,
            items: res.data.datas,
          }
        }
        return Promise.resolve()
      })
      this.loading = false
      // this.form.delivery_type = cat_code
      // this.form.prjoid = prj_oid
      // this.form.items = []
    },
    ok() {
      if (!this.form.items.length) {
        this.$message.success("保存成功")
        this.visible = false
        this.$emit("reload")
      }
      if (this.form.items.at(-1).is_end !== '1') {
        this.$message.error("最后一个步骤必须为最终步骤")
        return
      }
      this.confirmLoading = true
      fetch("post", "/prj/wfdefine/save", {
        ...this.form,
        items: this.form.items.map((item, index) => {
          return {
            ...item,
            step_seq: index + 1
          }
        })
      }).then(res => {
        if (res.status === 0) {
          this.$message.success("保存成功")
          this.visible = false
          this.$emit("reload")
          return Promise.resolve()
        } else {
          this.$message.error(res.message)
          return Promise.reject()
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    }
  },
  computed: {
    hasEnd() {
      return !!this.form.items.find(item => {
        return item.is_end === '1'
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.relationWorkModal {
  .ant-modal-body {
    padding: 0;
  }
}
/deep/ .ant-modal-title{
  font-weight: 500;
font-size: 18px;
color: #303030;
}
/deep/ .ant-table-header-column .ant-table-column-title{
  font-weight: 500;
font-size: 16px;
color: #000000;
}
/deep/ .ant-table-tbody > tr > td{
  color: #000000;
}
.icon{
  width: 21px;
  height: 21px;
  border-radius: 50%;
  margin-right: 8px;
}
.relation-work {
  padding: 0 17px;

  .blue {
    color: #4397E7;

  }

  .adddeliver {
    margin-bottom: 14px;
  }

}

.footer_left {
  float: left;
  font-weight: 500;
  font-size: 14px;
  color: #616161;
}
</style>