<template>
  <div class="app-main-content">
    <div class="content">
      <div class="content-left">
        <div class="title">交付物</div>
        <div class="addbtn">
          <a-button type="primary" @click="openModal()">
            添加新类型
          </a-button>
        </div>
        <a-modal class="modal" v-model="modal.visible" :title="(modal.type === 'create' ? '新增' : '编辑') + '交付物类型名称'" @ok="handleOk">
          <p>名称<i style="color:red">*</i></p>
          <div style="display: flex">
            <a-input v-model="modal.form.cat_name" placeholder="" style="margin-bottom: 20px" />
            <a-select v-model="modal.form.temp10" style="width: 120px;margin-left: 10px;height: 32px;">
              <a-select-option value="D">文档</a-select-option>
              <a-select-option value="P">产品</a-select-option>
            </a-select>
          </div>
        </a-modal>

        <a-table
          style="width: 100%"
          :data-source="cat.delivery_type"
          rowKey="oid"
          :rowSelection="{
            selectedRowKeys,
            onChange: changeSelected
          }"
          :pagination="false"
          :customRow="customRow"
        >
          <a-table-column title="ID" data-index="seqid" width="80px"></a-table-column>
          <a-table-column title="交付物类型名称" data-index="cat_name">
          </a-table-column>
          <a-table-column title="类型" width="550px">
            <template v-slot:default="_, row">
              <span class="blue">
                <span v-if="row.temp10 === 'D'">文档</span>
                <span v-if="row.temp10 === 'P'">产品</span>
              </span>
            </template>
          </a-table-column>
          <a-table-column title="操作" data-index="状态">
            <template v-slot:default="_, row">
              <span class="blue" @click.stop="updateCat(row)" style="cursor: pointer">
                修改
              </span>
              <span class="blue" @click.stop="delCat(row)" style="cursor: pointer">
                删除
              </span>
              <span class="blue" v-if="row.temp10 === 'D'" @click.stop="openDeliverApproval(row)" style="cursor: pointer">
                工作流设置
              </span>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <div class="content-right" v-if="detailOid">
        <a-tabs v-model="detailTab">
          <a-tab-pane key="1" tab="动态">
          </a-tab-pane>
          <a-tab-pane key="2" tab="详情">
          </a-tab-pane>
        </a-tabs>
        <bizlog v-if="detailTab === '1'" :source-oid="detailOid"></bizlog>
        <div v-if="detailTab === '2'" style="padding: 10px">
          <a-table :pagination="false" :data-source="detailList" rowKey="oid">
            <a-table-column title="步骤编号" data-index="step_seq"></a-table-column>
            <a-table-column title="步骤名称" data-index="step_name"></a-table-column>
            <a-table-column title="步骤角色">
              <template v-slot:default="_, row">
                <div class="user-group">
                  {{getRoleName(row.step_role)}}
                  <!--                    <img :src="row." alt="">-->
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
      <DeliverModal ref="modal" @reload="getCatRetrieve()"></DeliverModal>
    </div>
  </div>
</template>

<script>
import DeliverModal from "@/components/DeliverModal";
import Bizlog from "@/components/bizlog.vue";
import {fetch} from "@/utils/request.js";

export default {
  name: "projectPlanDeliverable",
  data() {
    return {
      detailOid: "",
      detailTab: "1",
      modal: {
        visible: false,
        type: 'create',
        form: {
          oid: "",
          cat_name: "",
          cat_code: "",
          temp10: ""
        }
      },
      cat: {
        delivery_type: []
      },
      detailList: [],
      listQuery: {
        prjoid: this.$route.query.prj_oid
      },
      value: '',
      selectedRowKeys: [],
      allRole: [],
    }
  },
  components: {
    Bizlog,
    DeliverModal
  },
  created() {
    this.getAllRole(this.$route.query.prj_oid)
    this.getCatRetrieve()
  },
  methods: {
    getRoleName(oid) {
      return this.allRole.find(item => item.oid === oid)?.role_name
    },
    getCatRetrieve() {
      fetch("post", "/prj/cat/retrieve", {
        prjoid: this.$route.query.prj_oid,
        cat_type: 'delivery_type',
        page_size: 999
      }).then(res => {
        if (res.status === 0) {
          this.cat.delivery_type = res.data.datas
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAllRole(prj_oid) {
      return fetch("post", "prj/all/role/retrieve/byprj", {
        prjoid: prj_oid,
      }).then(res => {
        if (res.status === 0) {
          this.allRole = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    updateCat(record) {
      this.modal.type = "update"
      this.modal.form.oid = record.oid
      this.modal.form.cat_code = record.cat_code
      this.modal.form.cat_name = record.cat_name
      this.modal.form.temp10 = record.temp10
      this.modal.visible = true
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.detailOid = record.oid
            fetch("post", "/prj/wfdefine/retrieve", {
              delivery_type: record.cat_code,
              prjoid: this.$route.query.prj_oid,
              page_size: 99,
            }).then(res => {
              if (res.status === 0) {
                this.$set(this, 'detailList', res.data.datas)
              }
            })
          }
        }
      }
    },
    openModal() {
      this.modal.type = "create"
      this.modal.form.oid = ""
      this.modal.form.cat_code = ""
      this.modal.form.cat_name = ""
      this.modal.form.temp10 = ""
      this.modal.visible = true
    },
    changeSelected(list) {
      console.log(list)
      this.selectedRowKeys = list
    },
    openDeliverApproval(row) {
      this.$refs.modal.open(row, this.$route.query.prj_oid)
    },
    handleOk() {
      if (!this.modal.form.temp10) {
        this.$message.error("请选择类型")
        return Promise.reject()
      }else if (!this.modal.form.cat_name) {
        this.$message.error("请输入名称")
        return Promise.reject()
      }
      let url = "/prj/cat/update"
      let form = {
        cat_type: "delivery_type",
        oid: this.modal.form.oid,
        cat_name: this.modal.form.cat_name,
        cat_code: this.modal.form.cat_code,
        temp10: this.modal.form.temp10,
        prjoid: this.$route.query.prj_oid
      }
      if (this.modal.type === 'create') {
        url = "/prj/cat/create"
        form = {
          cat_type: "delivery_type",
          cat_name: this.modal.form.cat_name,
          temp10: this.modal.form.temp10,
          prjoid: this.$route.query.prj_oid
        }
      }
      return fetch("post", url, form).then(res => {
        if (res.status === 0) {
          this.$message.success((this.modal.type === 'create' ? '创建' : '修改')+'成功')
          this.getCatRetrieve()
          this.modal.visible = false;
        }
      })
    },
    delCat(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          fetch('post','/prj/cat/delete',{
            oid: row.oid
          }).then((res)=>{
            if (res.status === 0) {
              this.getCatRetrieve()
              this.$message.success('删除成功！')
            }else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      });
    }
  }
}
</script>

<style scoped lang="less">
.modal {
  /deep/ .ant-modal-title {
    font-weight: 500;
    font-size: 18px;
    color: #303030;
  }

  p {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;

  }
}

.app-main-content {
  padding: 0 7px;
  //height: calc(100% - 50px);



  .content {
    height: calc(100%);

    display: flex;
    align-items: center;
    overflow: hidden;

    .content-left {
      flex: 1;
      height: 100%;
      padding: 0 23px;
      background-color: #FFF;
      overflow-y: auto;
      box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.50);
      .user-group {
        display: flex;
        align-items: center;
        >img {
          width: 25px;
          height: 25px;
          margin-right: 8px;
        }
      }

      .title {
        line-height: 60px;
        font-weight: 500;
        font-size: 16px;
        color: #303030;
        border-bottom: 1px solid #B6B6B6;
      }

      .addbtn {
        text-align: right;
        margin: 15px 0px;
      }

      .blue {
        color: #4397E7;
        margin-right: 18px;
      }

    }

    .content-right {
      width: 380px;
      flex-shrink: 0;
      height: 100%;
      background-color: #FFF;
      margin-left: 13px;
      /deep/.ant-tabs-top-bar {
        margin-bottom: 0;
      }
    }
  }
}
</style>
