import { render, staticRenderFns } from "./projectSettingDeliver.vue?vue&type=template&id=ab94a634&scoped=true&"
import script from "./projectSettingDeliver.vue?vue&type=script&lang=js&"
export * from "./projectSettingDeliver.vue?vue&type=script&lang=js&"
import style0 from "./projectSettingDeliver.vue?vue&type=style&index=0&id=ab94a634&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab94a634",
  null
  
)

export default component.exports