<template>
  <div class="biz-log">
    <div class="biz-top">
      <div class="biz-pagination"><a-pagination simple v-model="page.page_index" size="small" :showLessItems="true" :pageSize="5" :total="page.total" @change="onChange"/></div>
      <div class="biz-num">共{{page.total_page_count}}页 {{page.total}}条</div>
    </div>
    <div  v-if="list.length === 0" style="margin-top: 20px">
      <edp-empty></edp-empty>
    </div>
    <div class="biz-log-items d-flex" v-for="(item,index) in list" :key="index">
      <div class="icon">
<!--        <i class="iconfont icon-zichanguanli" style="font-size: 16px;color: #909090;"></i>-->
        <img v-if="item.operation === '更新状态'" :src="$toUrl('code.png')" alt="">
        <img v-else-if="item.operation === '关联'" :src="$toUrl('relation.png')" alt="">
        <img v-else-if="item.operation === '编辑属性'" :src="$toUrl('edit_attr.png')" alt="">
        <img v-else-if="item.operation === '上传附件' || item.operation === '上传交付物'" :src="$toUrl('log_file.png')" alt="">
        <img v-else :src="$toUrl('update.png')" alt="">
      </div>
      <div class="info">
        <div class="name">{{ item.privatename }}：<span>{{item.create_time}}</span><span>{{item.remark}}</span></div>
        <div class="files" v-if="item?.files?.length > 0">
          <template v-for="file in item.files">
            <a :key="file.oid" v-if="file.file_name !== 'APPNULL'" class="file-item" :href="$toUrl(file.file_path)" target="_blank">
              <!--<a v-for="file in item.files" :key="file.oid" class="file-item" @click="downloadFile($toUrl(file.file_path),file.file_name+file.file_type)">-->
              <a-icon type="link" />
              <span class="file-name">{{ file.file_name }}{{file.file_type}}</span>
            </a>
            <div :key="file.oid" v-else>交付文件已删除</div>
          </template>
        </div>
        <div class="detail d-flex a-i-center">
          <div class="field-name" v-if="item.field_name">{{ item.field_name }}</div>
          <div class="unfinish" v-if="item.old_value">{{item.old_value}}</div>
          <div class="icon" v-if="item.old_value && item.new_value">
            <i class="iconfont icon-xiangxia"></i>
          </div>
          <div class="finish" v-if="item.new_value">{{ item.new_value }}</div>
<!--          <div>{{item.create_time}}</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetch } from "@/utils/request.js";

export default {
  name: "bizlog",
  props: {
    sourceOid: {
      type: String,
      required: true
    },
    operation: {
      type: String,
      default:() => ""
    }
  },
  watch: {
    sourceOid: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log(val);
        if (val) {
          this.getBizLogList()
        } else {
          this.list = []
        }
      }
    }
  },
  data() {
    return {
      list: [],
      page: {
        page_size: 5,
        page_index: 1,
        total: 0,
        total_page_count: 0
      }
    }
  },
  methods: {
    getBizLogList() {
      let query = {
        source_oid: this.sourceOid,
      }
      if (this.operation) {
        query.operation = this.operation
      }
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/bizlog/retrieve" : "/bizlog/retrieve"
      fetch("post", url, {
        source_oid: this.sourceOid,
        operation: this.operation,
        page_index: this.page.page_index,
        page_size: this.page.page_size
      }).then(res => {
        if (res.status === 0 || window.mock) {
          this.list = res.data.datas
          this.page = res.data.page
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 下载文件
    downloadFile(url, fileName) {
      this.isLoading = true
      const x = new XMLHttpRequest()
      x.open("GET", url, true)
      x.responseType = 'blob'
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
        this.isLoading = false
        console.log(this.isLoading)
      }
      x.send()
    },
    onChange(current) {
      console.log(current);
      this.page.page_index = current
      this.getBizLogList()
    }
  }
}
</script>

<style lang="less" scoped>
.biz-top {
  padding-left: 10px;
  height: 55px;
  line-height: 55px;
  box-sizing: border-box;
  border-bottom: 1px solid #EDEDED;
  font-size: 14px;
  color: #000000;
  display: flex;
  align-items: center;

  /deep/ .ant-pagination{
    margin-right: 10px;

    .ant-pagination-prev, .ant-pagination-next{
      border: 1px solid #909090;

      &.ant-pagination-prev{
        margin-right: 10px;
      }

      a{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #909090;
      }

    }

    .ant-pagination-item{
      border: 1px solid #909090;
      margin-right: 10px;

      &.ant-pagination-item-active{
        border-color: #ff6700;
      }

      &:hover{
        border-color: #ff6700;
      }
    }
  }
}

.biz-log-items {
  padding: 13px 13px 0 13px;
  //align-items: baseline;

  .icon{
    padding-top: 2px;
    img{
      width: 16px;
      height: 16px;
      display: block;
    }
  }

  .info {
    padding-left: 14px;
    font-size: 12px;
    color: #909090;

    .name {
      color: #000000;
      margin-bottom: 9px;

      > span {
        color: #909090;
        margin-right: 5px;

        &:nth-last-child(1){
          margin-right: 0;
        }
      }
    }

    .detail {
      flex-wrap: wrap;
      align-items: center;
      .field-name {
        flex-shrink: 0;
      }
      .icon {
        .iconfont {
          transform: rotate(-90deg);
          display: block;
        }
      }

      > div {
        margin-bottom: 5px;
        margin-right: 7px;
      }

      > *:nth-last-child(1) {
        margin-left: 0;
      }

      .finish {
        color: #24B47E;
        background-color: #E9F7F2;
        padding: 0 5px;
      }

      .unfinish {
        color: #EA0D0D;
        background: #FDE6E6;
        padding: 0 5px;
        text-decoration:line-through;
      }


    }
    .files {
      line-height: 25px;
      margin-bottom: 9px;
      .file-item {
        margin-right: 10px;
        font-size: 14px;
        .file-name {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
